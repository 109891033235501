@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.3.0/font/bootstrap-icons.css");

span{
    background: rgba(0,0,0,0) !important;
}

.table-footer {
    background-color: #8080801f;
}

html {
    background-color: #11232f;
    color: white
}

body {
    background-color: #11232f !important;
    color: white !important;
    padding-bottom: 2rem;
}

a.header {
    text-decoration: none !important;
    color: white !important;
    font-size: 20px;
    padding-left: 7px;
    padding-right: 7px;
    padding-bottom: 4px;
}

.header-links {
    align-self: flex-end;
}

div.header-group {
    display: flex;
}

div.body-content {
    padding-top: 1rem;
    padding-right: 1rem;
    padding-left: 1rem;
}

.css-b62m3t-container {
    color: black !important;
}

.player-details {
    padding-top: 20px;
}

table {
    font-size: .9rem;
}

.table-footer {
    background-color: #3d5b6e;
}

.todays-game {
    background-color: #3f6c93;
}

.view-details-btn {
    padding: 0 .3rem 0 .3rem !important;
}

.game-detail-start-time {
    padding-top: 15px;
}

.position-header {
    background-color: #5b5e5e;
}

.player-link {
    text-decoration: none !important;
    color: rgb(223, 252, 255) !important;
}

.previous {
    background-color: #f1f1f1;
    color: black;
    border-radius: 4px;
}

.right-border {
    border-right: solid;
}

.left-border {
    border-left: solid;
}

.team-logo {
    height: 75px;
    padding-right: 5px;
}

.game-preview-video {
    text-align: center;
}

.prop-row {
    background-color: #304856;
}

div.prop-row div:first-child {
    padding-left: 20px;
}

div.roster-container {
    padding-left: 10px;
    vertical-align: top;
}

.text-over{
    color: rgb(126 225 179) !important;
}

.recharts-tooltip-wrapper{
    color: black;
}

tspan {
    fill: white;
}

.btn.btn-primary{
    background-color: #0dcaf0;
    --bs-btn-border-color: #0dcaf0;
}

.spinner-container{
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -50px;
    margin-left: -50px;
    width: 100px;
    height: 100px;
}

.panel {
    background-color: #274559;
    border-radius: 25px;
    padding: 15px;
    border: 2px solid white;
}